import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Mankind - What a shame,`}<br parentName="p"></br>{`
`}{`Venture their bad habit on pigs and mice,`}<br parentName="p"></br>{`
`}{`In torment untold, they’re purchased and sold,`}<br parentName="p"></br>{`
`}{`In lab or cultivated, they’re executed and hurt,`}<br parentName="p"></br>{`
`}{`People with no disgrace substitute and fault,`}<br parentName="p"></br>{`
`}{`They stun and consume at that point thusly,`}<br parentName="p"></br>{`
`}{`A reality they twist to suit their end.`}<br parentName="p"></br>{`
`}{`In research labs from seeing.`}<br parentName="p"></br>{`
`}{`There are laborers paid to do.`}<br parentName="p"></br>{`
`}{`Disgusting strategies on living spirits.`}<br parentName="p"></br>{`
`}{`So eager supervisors can arrive at their objectives.`}<br parentName="p"></br>{`
`}{`Scientists harm and do likewise:`}<br parentName="p"></br>{`
`}{`They consume and cut,`}<br parentName="p"></br>{`
`}{`At that point smile and swagger`}<br parentName="p"></br>{`
`}{`To see their terrible wrongdoings on paper:`}<br parentName="p"></br>{`
`}{`Distribute or die, name of the game.`}<br parentName="p"></br>{`
`}{`The tricked public gives out money`}<br parentName="p"></br>{`
`}{`So labs can slam like such a lot of waste`}<br parentName="p"></br>{`
`}{`Bunnies utilized, remorselessly manhandled.`}<br parentName="p"></br>{`
`}{`Pain-free income, awards give.`}<br parentName="p"></br>{`
`}{`They keep individuals on their side`}<br parentName="p"></br>{`
`}{`With unlimited guarantees of “fix.”`}<br parentName="p"></br>{`
`}{`They use infections as a draw.`}<br parentName="p"></br>{`
`}{`The sociopaths who scam them`}<br parentName="p"></br>{`
`}{`Behind their backs snicker and sneer.`}<br parentName="p"></br>{`
`}{`Individuals think they battle illness`}<br parentName="p"></br>{`
`}{`What’s more, with their cash attempt to please`}<br parentName="p"></br>{`
`}{`The shrewd people who guarantee wellbeing`}<br parentName="p"></br>{`
`}{`In any case, live and make progress toward expanded riches.`}<br parentName="p"></br>{`
`}{`These specialists, without any morals,`}<br parentName="p"></br>{`
`}{`Advance mediation rather than counteraction.`}<br parentName="p"></br>{`
`}{`With “infections” made`}<br parentName="p"></br>{`
`}{`The media are satiated.`}<br parentName="p"></br>{`
`}{`This is the sort the public help`}<br parentName="p"></br>{`
`}{`Furthermore, hold in high regard.`}<br parentName="p"></br>{`
`}{`People in general purchase a wide range of untruths.`}<br parentName="p"></br>{`
`}{`They never hear the hushed cries`}<br parentName="p"></br>{`
`}{`Of animals trapped in profound sadness.`}<br parentName="p"></br>{`
`}{`People decide to believe they’re given consideration`}<br parentName="p"></br>{`
`}{`When there is nothing however despondency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      